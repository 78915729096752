import firebase from './firebase.js';
import {detect} from 'detect-browser';
import {getAppEnv} from 'features/HelperFunctions.js';

function getFirebaseCollection(url) {
  const appEnv = getAppEnv(url);
  if (appEnv === 'local') {
    return 'webfrontend-localhost-logs';
  } else if (appEnv === 'dev') {
    return 'webfrontend-dev-logs';
  } else {
    return 'webfrontend-logs';
  }
}

function getEventName(errorType) {
  let eventName = 'webapp_error_';
  if (errorType === 'api') {
    eventName += 'api';
  } else if (errorType === 'client-side') {
    eventName += 'js';
  } else if (errorType === 'crash') {
    eventName += 'crash';
  } else {
    return null;
  }

  return `${eventName}_${getAppEnv()}`;
}

export const firebaseErrorLogger = (errorEnvironment) => {
  const db = firebase.firestore();
  const browser = detect();
  const user = window.localStorage.getItem('zabble-user-name');
  const email = window.localStorage.getItem('zabble-email');

  const errorLogObject = {
    date: new Date(),
    // app_version: 'I1STJVX6UPHBOG',
    browser: browser.name + ' ' + browser.version,
    device: browser.os,
    user: user ? user : 'Error retrieving user data',
    email: email ? email : 'Error retrieving email data',
    route: errorEnvironment.route,
    message: errorEnvironment.message,
  };

  if (errorEnvironment.type === 'api') {
    errorLogObject.type = 'API error';
    errorLogObject.function = errorEnvironment.function;
    if (errorEnvironment.requestBody) {
      errorLogObject.requestBody = errorEnvironment.requestBody;
    }
  } else if (errorEnvironment.type === 'client-side') {
    errorLogObject.type = 'React / Client-side error';
  } else if (errorEnvironment.type === 'crash') {
    errorLogObject.type = 'Webapp crash';
    if (errorEnvironment.componentStack) {
      errorLogObject.componentStack = errorEnvironment.componentStack;
    }
  }

  // log to Analytics
  const eventName = getEventName(errorEnvironment.type);
  if (eventName) {
    const gaParams = {...errorLogObject};
    delete gaParams.requestBody;
    delete gaParams.componentStack;
    firebase.analytics().logEvent(eventName, gaParams);
  }

  // log to Firestore
  const collection = getFirebaseCollection(errorLogObject.route);

  firebase
    .auth()
    .signInAnonymously()
    .then(() => db.collection(collection).add(errorLogObject))
    .catch((err) => console.log(err.message));
};
