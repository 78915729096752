import React from 'react';
import ReactDOM from 'react-dom';
import 'bulma/css/bulma.min.css';
import './index.css';
import Amplify from 'aws-amplify';
import * as serviceWorker from './serviceWorker';
import {createBrowserHistory} from 'history';
import AppProviders from './auth/_context-agents/auth-index';
import AuthSplitter from './AuthSplitter';
import {LicenseInfo} from '@mui/x-data-grid-pro';

LicenseInfo.setLicenseKey(
  '8934cd3909ce5b67edd97443d135345aT1JERVI6NDA4MDcsRVhQSVJZPTE2ODAyODgxMjAwMDAsS0VZVkVSU0lPTj0x',
);

Amplify.configure({
  Auth: {
    mandatorySignId: true,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USERPOOL_ID,
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: 'api_platform',
        endpoint: process.env.REACT_APP_DASHBOARD_LAMBDA,
        region: process.env.REACT_APP_REGION,
      },
    ],
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_BUCKET_NAME,
      region: process.env.REACT_APP_REGION,
    },
  },
});

const history = createBrowserHistory();

const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
if (path) {
  history.replace(path);
}

ReactDOM.render(
  <AppProviders>
    <AuthSplitter />
  </AppProviders>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
