import axios from 'axios';
import {getAuthToken} from 'auth/_context-agents/auth-context';

// Dashboard --------------------------------------------------------- //
export const dashboardFetch = async function (user) {
  const token = await getAuthToken();
  const headers = {Authorization: token};
  const body = {
    email_id: user,
    tab: 'dashboard',
    SessionLifetimeInMinutes: 44000,
  };

  const fetchCall = fetch(process.env.REACT_APP_DASHBOARD_LAMBDA, {
    SessionLifetimeInMinutes: 44000,
    method: 'POST',
    mode: 'cors',
    withCredentials: true,
    cache: 'no-cache',
    credentials: 'omit',
    body: JSON.stringify(body),
    headers: headers,
    redirect: 'follow',
    referrer: 'no-referrer',
  });
  return fetchCall;
};

// Alerts --------------------------------------------------------- //

export const alertFetch = async function (user) {
  const token = await getAuthToken();
  const headers = {Authorization: token};
  const body = {
    email_id: user,
    tab: 'alerts_initial',
  };
  const fetchCall = fetch(process.env.REACT_APP_ALERTS_LAMBDA, {
    method: 'POST',
    mode: 'cors',
    withCredentials: true,
    cache: 'no-cache',
    credentials: 'omit',
    body: JSON.stringify(body),
    headers: headers,
    redirect: 'follow',
    referrer: 'no-referrer',
  });
  return fetchCall;
};

export const alertSave = async function (user, query) {
  const token = await getAuthToken();
  const headers = {Authorization: token};
  const body = {
    email_id: user,
    query: query,
  };
  const fetchCall = fetch(process.env.REACT_APP_ALERTS_LAMBDA, {
    method: 'POST',
    mode: 'cors',
    withCredentials: true,
    cache: 'no-cache',
    credentials: 'omit',
    body: JSON.stringify(body),
    headers: headers,
    redirect: 'follow',
    referrer: 'no-referrer',
  });
  return fetchCall;
};

export const alertDelete = async function (user, query) {
  const token = await getAuthToken();
  const headers = {Authorization: token};
  const body = {
    email_id: user,
    delete: query,
  };
  const fetchCall = fetch(process.env.REACT_APP_ALERTS_LAMBDA, {
    method: 'POST',
    mode: 'cors',
    withCredentials: true,
    cache: 'no-cache',
    credentials: 'omit',
    body: JSON.stringify(body),
    headers: headers,
    redirect: 'follow',
    referrer: 'no-referrer',
  });
  return fetchCall;
};

// Images --------------------------------------------------------- //

export const imageFetch = async function (user) {
  const token = await getAuthToken();
  const headers = {Authorization: token};
  const body = {
    email_id: user,
    tab: 'imagesV1',
  };
  const fetchCall = fetch(process.env.REACT_APP_DASHBOARD_LAMBDA, {
    method: 'POST',
    mode: 'cors',
    withCredentials: true,
    cache: 'no-cache',
    credentials: 'omit',
    body: JSON.stringify(body),
    headers: headers,
    redirect: 'follow',
    referrer: 'no-referrer',
  });
  return fetchCall;
};

export const fetchImages = async function (user) {
  const token = await getAuthToken();
  const headers = {Authorization: token};
  const body = {
    email_id: user,
  };
  const fetchCall = fetch(process.env.REACT_APP_IMAGES_LAMBDA, {
    method: 'POST',
    mode: 'cors',
    withCredentials: true,
    cache: 'no-cache',
    credentials: 'omit',
    body: JSON.stringify(body),
    headers: headers,
    redirect: 'follow',
    referrer: 'no-referrer',
  });
  return fetchCall;
};

// Templates --------------------------------------------------------- //
export const templateDeleteApiCall = async function (user, id) {
  const token = await getAuthToken();
  const headers = {Authorization: token};
  const body = {
    email_id: user,
    tab: 'deleteTemplate',
    template_id: id,
  };
  const fetchCall = fetch(process.env.REACT_APP_ZABBLEVIEW_LAMBDA, {
    method: 'POST',
    mode: 'cors',
    withCredentials: true,
    cache: 'no-cache',
    credentials: 'omit',
    body: JSON.stringify(body),
    headers: headers,
    redirect: 'follow',
    referrer: 'no-referrer',
  });
  return fetchCall;
};

export const templateDeployApiCall = async function (user, id) {
  const token = await getAuthToken();
  const headers = {Authorization: token};
  let body = {
    email_id: user,
    tab: 'deploy',
    template_id: id,
  };

  const fetchCall = fetch(process.env.REACT_APP_ZABBLEVIEW_LAMBDA, {
    method: 'POST',
    mode: 'cors',
    withCredentials: true,
    cache: 'no-cache',
    credentials: 'omit',
    body: JSON.stringify(body),
    headers: headers,
    redirect: 'follow',
    referrer: 'no-referrer',
  });
  return fetchCall;
};

export const templateCreateApiCall = async function (user, template) {
  const token = await getAuthToken();
  const headers = {Authorization: token};
  let body = {
    email_id: user,
    tab: 'saveTemplate',
    template: template,
  };

  const fetchCall = fetch(process.env.REACT_APP_ZABBLEVIEW_LAMBDA, {
    method: 'POST',
    mode: 'cors',
    withCredentials: true,
    cache: 'no-cache',
    credentials: 'omit',
    body: JSON.stringify(body),
    headers: headers,
    redirect: 'follow',
    referrer: 'no-referrer',
  });
  return fetchCall;
};

export const templateEditApiCall = async function (user, id, template) {
  const token = await getAuthToken();
  const headers = {Authorization: token};
  let body = {
    email_id: user,
    tab: 'editTemplate',
    uitemplate_id: id,
    template: template,
  };
  const fetchCall = fetch(process.env.REACT_APP_ZABBLEVIEW_LAMBDA, {
    method: 'POST',
    mode: 'cors',
    withCredentials: true,
    cache: 'no-cache',
    credentials: 'omit',
    body: JSON.stringify(body),
    headers: headers,
    redirect: 'follow',
    referrer: 'no-referrer',
  });
  return fetchCall;
};

// eslint-disable-next-line no-use-before-define
export const templateGetApiCall = async function (user) {
  const token = await getAuthToken();
  const headers = {Authorization: token};
  const body = {
    email_id: user,
    tab: 'getTemplates',
  };
  const fetchCall = await fetch(process.env.REACT_APP_ZABBLEVIEW_LAMBDA, {
    method: 'POST',
    mode: 'cors',
    withCredentials: true,
    cache: 'no-cache',
    credentials: 'omit',
    body: JSON.stringify(body),
    headers: headers,
    redirect: 'follow',
    referrer: 'no-referrer',
  });

  return fetchCall;
};

// Users --------------------------------------------------------- //

export const fetchUsers = async function (user) {
  const token = await getAuthToken();
  const headers = {Authorization: token};
  const body = {
    email_id: user,
    admin_action: 'get_users_list',
  };
  const fetchCall = fetch(process.env.REACT_APP_USERS_LAMBDA, {
    method: 'POST',
    mode: 'cors',
    withCredentials: true,
    cache: 'no-cache',
    credentials: 'omit',
    body: JSON.stringify(body),
    headers: headers,
    redirect: 'follow',
    referrer: 'no-referrer',
  });
  return fetchCall;
};

export const fetchUserByUsername = (token, username) => {
  var userData = JSON.stringify({
    operation: 'read',
    tableName: process.env.REACT_APP_GET_LOGINUSER_TABLE,
    payload: {
      Key: {
        usercode: username,
      },
    },
  });
  var config = {
    method: 'POST',
    url: process.env.REACT_APP_GET_NAME_OF_USER,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
    data: userData,
  };
  return axios(config);
};

export const fetchUserByEmail = (token, email) => {
  return axios.post(
    process.env.REACT_APP_GET_NAME_OF_USER,
    {
      operation: 'list',
      tableName: process.env.REACT_APP_GET_LOGINUSER_TABLE,
      payload: {
        IndexName: 'email-index',
        KeyConditionExpression: 'email = :v1',
        ExpressionAttributeValues: {
          ':v1': email,
        },
      },
    },
    {headers: {Authorization: token}},
  );
};

// Data Import --------------------------------------------------------- //

export async function fetchDataImports(customerId) {
  const token = await getAuthToken();

  return axios.post(
    process.env.REACT_APP_GET_NAME_OF_USER,
    {
      operation: 'list',
      tableName: process.env.REACT_APP_DATA_IMPORT_TABLE,
      payload: {
        IndexName: 'customer_id-usestatus-index',
        KeyConditionExpression: 'customer_id = :v1 and usestatus = :v2',
        ExpressionAttributeValues: {
          ':v1': customerId,
          ':v2': 'active',
        },
      },
    },
    {headers: {Authorization: token}},
  );
}

export async function fetchDataImportFiles(customerId, userEmail) {
  const token = await getAuthToken();

  return axios.post(
    process.env.REACT_APP_DATA_IMPORT_LAMBDA,
    {
      email_id: userEmail,
      customer_id: customerId,
      get_urls: true,
    },
    {headers: {Authorization: token}},
  );
}

export async function uploadDataForImport(
  fileName,
  fileData,
  userEmail,
  templateId,
) {
  const token = await getAuthToken();

  return axios.post(
    process.env.REACT_APP_DATA_IMPORT_LAMBDA,
    {
      fileName,
      data: fileData,
      email_id: userEmail,
      uitemplate_id: templateId,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    {headers: {Authorization: token}},
  );
}

// Single Sign-On --------------------------------------------------------- //

export function exchangeCodeForToken(authCode) {
  const domain = process.env.REACT_APP_COGNITO_DOMAIN;
  const awsRegion = process.env.REACT_APP_REGION;
  const authUrl = `https://${domain}.auth.${awsRegion}.amazoncognito.com/oauth2/token`;
  const reqData = {
    grant_type: 'authorization_code',
    client_id: process.env.REACT_APP_CLIENT_ID,
    redirect_uri: `${window.location.origin}${window.location.pathname}`,
    code: authCode,
  };
  const reqBody = Object.keys(reqData)
    .map((key) => `${key}=${reqData[key]}`)
    .join('&');
  return axios.post(authUrl, reqBody, {
    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
  });
}

export function exchangeRefreshForToken(refreshToken) {
  const domain = process.env.REACT_APP_COGNITO_DOMAIN;
  const awsRegion = process.env.REACT_APP_REGION;
  const authUrl = `https://${domain}.auth.${awsRegion}.amazoncognito.com/oauth2/token`;
  const reqData = {
    grant_type: 'refresh_token',
    client_id: process.env.REACT_APP_CLIENT_ID,
    refresh_token: refreshToken,
  };
  const reqBody = Object.keys(reqData)
    .map((key) => `${key}=${reqData[key]}`)
    .join('&');
  return axios.post(authUrl, reqBody, {
    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
  });
}

export function getCognitoUser(accessToken) {
  const domain = process.env.REACT_APP_COGNITO_DOMAIN;
  const awsRegion = process.env.REACT_APP_REGION;
  return axios.get(
    `https://${domain}.auth.${awsRegion}.amazoncognito.com/oauth2/userInfo`,
    {headers: {Authorization: `Bearer ${accessToken}`}},
  );
}

export async function acceptTermsSso(username) {
  const token = await getAuthToken();
  return axios.post(
    process.env.REACT_APP_GET_NAME_OF_USER,
    {
      operation: 'update',
      tableName: process.env.REACT_APP_GET_LOGINUSER_TABLE,
      payload: {
        Key: {usercode: username},
        UpdateExpression: 'set sso_accepted_terms=:f',
        ExpressionAttributeValues: {':f': true},
        ReturnValues: 'UPDATED_NEW',
      },
    },
    {headers: {Authorization: token}},
  );
}
