import React from 'react';
import {useAuth} from './auth-context';

const UserContext = React.createContext();

function UserProvider(props) {
  const {
    data: {user},
  } = useAuth();
  return <UserContext.Provider value={user} {...props} />;
}

function getEmail(context) {
  if (context) {
    if (context.email) {
      return context.email;
    }

    if (context.attributes && context.attributes.email) {
      return context.attributes.email;
    }

    if (context.challengeParam && context.challengeParam.userAttributes.email) {
      return context.challengeParam.userAttributes.email;
    }
  }

  return 'Email unknown';
}

function useUser() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }

  return context;
}

function useEmail() {
  const context = React.useContext(UserContext);
  return getEmail(context);
}

export {UserProvider, useUser, useEmail};
