import React from 'react';
import {useUser} from 'auth/_context-agents/user-context';
import {useAuth} from 'auth/_context-agents/auth-context';

const App = React.lazy(() => import('./App'));
const UnauthenticatedApp = React.lazy(() => import('./Unauthenticated'));

function AuthSplitter() {
  const data = useUser();
  const {isLoading} = useAuth();

  if (isLoading) {
    return <div />;
  }

  return (
    <>
      <React.Suspense fallback={<div></div>}>
        {data ? (
          <App data={data}></App>
        ) : (
          <UnauthenticatedApp></UnauthenticatedApp>
        )}
      </React.Suspense>
    </>
  );
}

export default AuthSplitter;
