import moment from 'moment';
import {firebaseErrorLogger} from 'utils/firebase-logger';

// upper case first letter of string
export const capitalizeEachWord = (string) => {
  return (
    string &&
    string.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1);
    })
  );
};

// get date time now in “MM-dd-yy HH:mm:ss” format
export const getCurrentDateTime = (date) => {
  var options = {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  };
  let currentDate = date.toLocaleString('en-US', options);
  const currentDateTime = currentDate.replace(/\//g, '-').replace(/,/g, '');
  return currentDateTime;
};

// get current date time from YYYY-MM-DD
export const getCurrentDateTimeFromSimpleDateTime = (dateTime) => {
  let date = dateTime.slice(8, 10);
  if (date < 10) {
    date = date[1];
  }
  const newMonth = dateTime.slice(5, 7);
  const year = dateTime.slice(0, 4);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const month = months[Number(newMonth) - 1];
  return month + ' ' + date + ', ' + year;
};

// get current date time to Monday, August 29, 2022, 6:38pm format
export const convertDateTime = (dateTime) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const weekDay = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const newMonth = dateTime && dateTime.slice(0, 2);
  const newMonthInText = months[Number(newMonth) - 1] + ' ';
  const newDateTimeString =
    newMonthInText &&
    newMonthInText.concat(
      dateTime && dateTime.substring(3, 5),
      ', ',
      dateTime && dateTime.substring(6, 19),
    );
  const d = new Date(newDateTimeString);
  const day = weekDay[d.getDay()];
  const month = months[d.getMonth()];
  const ampm = d.getHours() >= 12 ? 'pm' : 'am';
  let minutes = d.getMinutes();
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  let hours = d.getHours();
  if (hours > 12) {
    hours = hours % 12;
  }
  return (
    day +
    ', ' +
    month +
    ' ' +
    d.getDate() +
    ', ' +
    d.getFullYear() +
    ', ' +
    hours +
    ':' +
    minutes +
    ampm
  );
};

// get current date time to August 26, 2022 12:54pm from old format (MM-DD-YYYY HH:mm:ss)
export const convertDateTimeInAlertSettings = (dateTime) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const newMonth = dateTime && dateTime.slice(0, 2);
  const newMonthInText = months[Number(newMonth) - 1] + ' ';
  const newDateTimeString =
    newMonthInText &&
    newMonthInText.concat(
      dateTime && dateTime.substring(3, 5),
      ', ',
      dateTime && dateTime.substring(6, 19),
    );
  const d = new Date(newDateTimeString);
  const month = months[d.getMonth()];
  const ampm = d.getHours() >= 12 ? 'pm' : 'am';
  let minutes = d.getMinutes();
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  let hours = d.getHours();
  if (hours > 12) {
    hours = hours % 12;
  }
  return (
    month +
    ' ' +
    d.getDate() +
    ', ' +
    d.getFullYear() +
    '\n' +
    hours +
    ':' +
    minutes +
    ampm
  );
};

// capitalize first letter of a string
export const capitalized = (str, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
    match.toUpperCase(),
  );

// get current date time to August 26, 2022 12:54pm from old format (MM-DD-YYYY HH:mm:ss)
export const convertDateTimeInAlerts = (dateTime) => {
  if (dateTime === undefined) return null;
  const timeObject =
    dateTime !== undefined
      ? dateTime.replace(/pst/gi, '').match(/\d\d:\d\d:\d\d\s\w\w/i)
      : 'No time given';
  const [time, modifier] = timeObject[0].split(' ');
  let [hours, minutes] = time.split(':');
  if (hours === '12') {
    hours = '00';
  }
  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  } else {
    hours = parseInt(hours);
  }
  if (hours > 12) {
    hours = hours % 12;
  }
  let calendarDate =
    dateTime !== undefined
      ? dateTime.match(/^.*(?=\s\d{4})/).toString()
      : 'No date given';
  if (calendarDate[4] === '0') {
    calendarDate = calendarDate.substring(0, 4) + calendarDate.substring(5);
  }
  const calendarYear = dateTime !== undefined ? dateTime.match(/\d{4}/) : '';
  return (
    calendarDate + ', ' + calendarYear + ' ' + hours + ':' + minutes + modifier
  );
};

// function to check if uitemplate_id is matching return template name
export const getTemplateNameFromTemplateId = (allTemplates, templateId) => {
  for (let i = 0; i < allTemplates.length; i++) {
    if (allTemplates[i].uitemplate_id === templateId) {
      return allTemplates[i].uitemplate_name;
    }
  }
};

// format date from react-dates for writing to DynamoDb
export const formatDateForQuery = (dateInput) => {
  let date = moment(dateInput).date();
  let month = moment(dateInput).month() + 1;
  const year = moment(dateInput).year();
  if (date < 10) {
    date = '0' + date;
  }
  if (month < 10) {
    month = '0' + month;
  }
  return year + '-' + month + '-' + date;
};

/* For invoices table */
// convert UTC date time from DynamoDB with ISO format to show date time
export const convertDateTimeFromDynamoUTC = (dateTime) => {
  // dateTime from db: 2022-09-10 17:01:50
  // convert dateTime to iso format: 2022-09-10T17:01:50.000Z
  // use new Date (2022-09-10T17:01:50.000Z) to get date time in local time
  const newDateTime = new Date(dateTime.replace(' ', 'T') + 'Z');
  // localTime: 9/10/2022, 10:01:50 AM
  const localTime = newDateTime.toLocaleString();
  // convert localTime to new Date object
  const currentDateTime = new Date(localTime);
  // get month, day, year, hour, minute from currentDateTime
  const date = currentDateTime.getDate();
  const newMonth = currentDateTime.getMonth();
  const year = currentDateTime.getFullYear();
  let hour = currentDateTime.getHours();
  let minute = currentDateTime.getMinutes();
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  // get month name
  const month = months[Number(newMonth)];
  // convert hour to 12 hour format
  const ampm = hour >= 12 ? 'pm' : 'am';
  // if hour is greater than 12, divide by 12 and get remainder
  if (hour > 12) {
    hour = hour % 12;
    // if hour is 0, set hour to 12
  } else if (hour === 0) {
    hour = 12;
  }
  // if minute is less than 10, add 0 before minute
  if (minute < 10) {
    minute = '0' + minute;
  }
  // return date time in format: September 10, 2022 10:01am
  return month + ' ' + date + ', ' + year + ' ' + hour + ':' + minute + ampm;
};

// convert UTC date time from DynamoDB with ISO format to show date only
export const convertDateFromDynamoUTC = (dateTime) => {
  // dateTime from db: 2022-09-10 17:01:50
  // convert dateTime to iso format: 2022-09-10T17:01:50.000Z
  // use new Date (2022-09-10T17:01:50.000Z) to get date time in local time
  const newDateTime = new Date(dateTime.replace(' ', 'T') + 'Z');
  // localTime: 9/10/2022, 10:01:50 AM
  const localTime = newDateTime.toLocaleString();
  // convert localTime to new Date object
  const currentDateTime = new Date(localTime);
  // get month, day, year from currentDateTime
  const date = currentDateTime.getDate();
  const newMonth = currentDateTime.getMonth();
  const year = currentDateTime.getFullYear();
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  // get month name
  const month = months[Number(newMonth - 1)];
  // return date time in format: September 10, 2022
  return month + ' ' + date + ', ' + year;
};

// save UTC date time to DynamoDB with ISO format
export const saveUTCDateTimeToDynamoDB = () => {
  // convert date time to iso format: 2022-09-10T17:01:50.000Z but remove T and Z to save to db
  return new Date().toISOString().slice(0, 19).replace('T', ' ');
};

/* end of invoices table */

// Used by buildDynamoUpdateExpression function
function isBlank(value) {
  return value === undefined || value === null || value === '';
}

// Used by buildDynamoUpdateExpression function
function getNextAttributeKey(current) {
  if (!current) {
    return ':a';
  }

  return `:${String.fromCharCode(current.charCodeAt(1) + 1)}`;
}

// builds the UpdateExpression and ExpressionAttributeValues for the dynamodbmanager lambda
// https://zabble.atlassian.net/wiki/spaces/ZAB/pages/13926419/logzero+dynamodb
export function buildDynamoUpdateExpression(data, isFieldEmpty = isBlank) {
  const setFields = Object.keys(data).filter((key) => !isFieldEmpty(data[key]));
  const removeFields = Object.keys(data).filter((key) =>
    isFieldEmpty(data[key]),
  );
  let attributeKey = getNextAttributeKey();
  const setExpressionParts = [];
  const attributes = {};

  setFields.forEach((field) => {
    setExpressionParts.push(`${field}=${attributeKey}`);
    attributes[attributeKey] = data[field];
    attributeKey = getNextAttributeKey(attributeKey);
  });

  const updateExpressionParts = [];
  if (setExpressionParts.length) {
    updateExpressionParts.push(`set ${setExpressionParts.join(', ')}`);
  }
  if (removeFields.length) {
    updateExpressionParts.push(`remove ${removeFields.join(', ')}`);
  }

  return {
    UpdateExpression: updateExpressionParts.join(' '),
    ExpressionAttributeValues: attributes,
  };
}

function getErrorMessage(error) {
  if (error) {
    if (error.message) {
      return error.message;
    } else if (error.toString()) {
      return error.toString();
    }
  }

  return 'An unknown error occurred';
}

export function logApiErrorToFirebase(error, func) {
  firebaseErrorLogger({
    type: 'api',
    route: window.location.href,
    function: func,
    message: getErrorMessage(error),
  });
}

export function logClientErrorToFirebase(error, feature) {
  firebaseErrorLogger({
    type: 'client-side',
    route: window.location.href,
    message: `${feature}: ${getErrorMessage(error)}`,
  });
}

export function encodeFileBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
}

export function readFileAsBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
}

export function getAppEnv(url) {
  if (!url) {
    url = window.location.href;
  }
  if (url.includes('local.zabblezero.com') || url.includes('localhost')) {
    return 'local';
  } else if (url.includes('dev.zabblezero.com')) {
    return 'dev';
  } else {
    return 'prod';
  }
}
