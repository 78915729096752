export const COLORS = {
  zabbleBlack: 'hsl(240,18%,13%)',
  darkGrey: 'hsl(0,0%,56%)',
  lightGrey: 'hsl(0,1%,74%)',
  grey94: 'hsl(0, 0%, 94%)',
  alertRed: 'hsl(347,75%,49%)',
  warningYellow: 'hsl(40,88%,53%)',
  positiveGreen: 'hsl(169,74%,32%)',
  warningOrange: 'hsl(12,74%,59%)',
  recyclingBlue: 'hsl(197,100%,47%)',
  compostGreen: 'hsl(143,69%,57%)',
  zabblePurple: 'hsl(264,48%,47%)',
  zabblePurpleCompliment: 'hsl(40,82%,45%)',
  zabbleLavender: 'hsl(264,70%,74%)',
  zabbleTeal: 'hsl(189,83%,43%)',
  primaryColor: '#6D3FB2',
  hoverColor: '#B28DEB',
  disabledColor: '#BCBBBB',
};

// mapping of login email domains to Cognito identity provider (idp) identifiers
export const SSO_PROVIDER_MAP = {
  'uncc.edu': 'uncc.edu',
  'charlotte.edu': 'uncc.edu',
  'watsonville.gov': 'watsonville.gov',
  'syr.edu': 'esf.edu',
  'esf.edu': 'esf.edu'

};

export const SSO_LOGOUT_MAP = {
  'uncc.edu': process.env.REACT_APP_UNCC_LOGOUT,
  'charlotte.edu': process.env.REACT_APP_UNCC_LOGOUT,
  'watsonville.gov': process.env.REACT_APP_WATSONVILLE_LOGOUT,
  'esf.edu': process.env.REACT_APP_SUNYESF_LOGOUT,
  'syr.edu': process.env.REACT_APP_SUNYESF_LOGOUT,
};

export const SSO_COOKIES = {
  token: 'sso_auth_token',
  awsToken: 'sso_aws_token',
  refreshToken: 'sso_refresh_token',
  isValid: `sso_token_valid`,
};
