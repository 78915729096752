import * as firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyBkAgTU5qwuSsDfX7LhILS9FB4kC293euM',
  authDomain: 'zabble-zero-6e69a.firebaseapp.com',
  databaseURL: 'https://zabble-zero-6e69a.firebaseio.com',
  projectId: 'zabble-zero-6e69a',
  storageBucket: 'zabble-zero-6e69a.appspot.com',
  messagingSenderId: '1084209488658',
  appId: '1:1084209488658:web:e7fa8d3fb2aec4f49d09cb',
  measurementId: 'G-3LRLE1P2VP',
};
firebase.initializeApp(config);

export default firebase;
