import React from 'react';
import {Auth} from 'aws-amplify';
import Cookies from 'js-cookie';
import {exchangeRefreshForToken, getCognitoUser} from '_api';
import {logApiErrorToFirebase} from 'features/HelperFunctions';
import {SSO_COOKIES, SSO_LOGOUT_MAP} from 'CONSTANTS';

const AuthContext = React.createContext();

function isSsoUser() {
  return !!Cookies.get(SSO_COOKIES.token);
}

async function refreshIfNeeded() {
  const isTokenValid = Cookies.get(SSO_COOKIES.isValid) === 'true';
  if (isTokenValid) {
    return;
  }
  const refreshToken = Cookies.get(SSO_COOKIES.refreshToken);
  if (!refreshToken) {
    return;
  }
  try {
    const resp = await exchangeRefreshForToken(refreshToken);
    const tokenData = resp.data;
    const expiry = (1 / (24 * 60 * 60)) * tokenData.expires_in;
    Cookies.set(SSO_COOKIES.token, tokenData.id_token, {expires: 30});
    Cookies.set(SSO_COOKIES.awsToken, tokenData.access_token, {
      expires: 30,
    });
    Cookies.set(SSO_COOKIES.isValid, 'true', {expires: expiry});
  } catch (e) {
    logApiErrorToFirebase(e, 'exchangeRefreshForToken');
  }
}

export async function getAuthToken() {
  if (isSsoUser()) {
    await refreshIfNeeded();
    return Cookies.get(SSO_COOKIES.token) || null;
  }

  try {
    const session = await Auth.currentSession();
    return session.idToken.jwtToken;
  } catch {
    return null;
  }
}

function AuthProvider(props) {
  const [data, setData] = React.useState({user: null});
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    userCheck();
  }, []);

  React.useEffect(() => {
    if (data && data.user) {
      setIsLoading(false);
    }
  }, [data]);

  async function userCheck() {
    if (isSsoUser()) {
      await refreshIfNeeded();
      const ssoToken = Cookies.get(SSO_COOKIES.awsToken);
      try {
        const awsResp = await getCognitoUser(ssoToken);
        const user = awsResp.data;
        window.localStorage.setItem('zabble-user-name', user.username);
        window.localStorage.setItem('zabble-email', user.email);
        setData({user: user});
      } catch (e) {
        logApiErrorToFirebase(e, 'getCognitoUser');
        setIsLoading(false);
      }
      return;
    }

    await Auth.currentAuthenticatedUser()
      .then((user) => {
        window.localStorage.setItem('zabble-user-name', user.username);
        window.localStorage.setItem('zabble-email', user.attributes.email);
        setData({user: user});
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsLoading(false);
      });
  }

  async function getToken() {
    const token = await getAuthToken();
    if (!token) {
      setData({user: null});
      signOut();
    }
    return token;
  }

  async function signIn(form) {
    const user = await Auth.signIn(form);
    window.localStorage.setItem('zabble-user-name', user.username);
    if (user.attributes) {
      window.localStorage.setItem('zabble-email', user.attributes.email);
    }
    setData({user: user});
  }

  async function signInSso(tokenData) {
    try {
      const resp = await getCognitoUser(tokenData.access_token);
      const userData = resp.data;
      const expiry = (1 / (24 * 60 * 60)) * tokenData.expires_in;
      Cookies.set(SSO_COOKIES.token, tokenData.id_token, {expires: 30});
      Cookies.set(SSO_COOKIES.awsToken, tokenData.access_token, {expires: 30});
      Cookies.set(SSO_COOKIES.refreshToken, tokenData.refresh_token, {
        expires: 30,
      });
      Cookies.set(SSO_COOKIES.isValid, 'true', {expires: expiry});
      window.localStorage.setItem('zabble-user-name', userData.username);
      window.localStorage.setItem('zabble-email', userData.email);
      setData({user: userData});
      return userData;
    } catch (e) {
      logApiErrorToFirebase(e, 'getCognitoUser');
    }
  }

  const signOut = (redirect = false) => {
    if (isSsoUser()) {
      Object.values(SSO_COOKIES).forEach((cookie) => {
        Cookies.remove(cookie);
      });
      if (redirect) {
        const email = localStorage.getItem('sso_email');
        if (!email) {
          window.location.href = '/';
          return;
        }
        const emailParts = email.split('@');
        const domain = emailParts[emailParts.length - 1];
        const logoutUrl = SSO_LOGOUT_MAP[domain];
        if (!logoutUrl) {
          window.location.href = '/';
          return;
        }

        const cognitoDomain = process.env.REACT_APP_COGNITO_DOMAIN;
        const awsRegion = process.env.REACT_APP_REGION;
        const redirectUrl = `https://${cognitoDomain}.auth.${awsRegion}.amazoncognito.com/logout?client_id=${process.env.REACT_APP_CLIENT_ID}&logout_uri=${logoutUrl}`;
        window.location.assign(redirectUrl);
      }
    } else {
      Auth.signOut();
      if (redirect) {
        window.location.href = '/';
      }
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        data,
        isSsoUser: isSsoUser(),
        getAuthToken: getToken,
        signIn,
        signInSso,
        signOut,
        userCheck,
      }}
      {...props}
    />
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

export {AuthProvider, useAuth};
